import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Slider from "react-slick"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { WriterWrap, Avatar, Writer, Section, SectionTitle, Container } from "../SectionTags"
import QuotationMark from "../Icons/QuotationMark"
import ItemHover from "../ItemHover"
import PrimaryButton from "../Button/PrimaryButton"
import ArrowIcon from "../Icons/ArrowIcon"
import BackArrowIcon from "../Icons/BackArrowIcon"
import BreakpointUp from "../Media/BreakpointUp"

const SliderWrap = styled.div`
  margin:0 0 30px;
`

const SlickArrow = styled.div`
  text-align: center;
  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    background: #fff;
    border: 1px solid #dedede;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 38px;
    height: 38px;
    ${BreakpointUp.xl`		
			width: 48px;
			height: 48px;
		`}
    ${BreakpointUp.xxl`		
			width: 58px;
			height: 58px;
		`}
		& svg {
      transition: transform ease 0.2s;
    }
  }
`
const RightArrow = styled.div`
  right: 10px;
	& :hover {
    & svg {
      transform: translateX(3px);
    }
  }
`
const LeftArrow = styled.div`
  left: 10px;
	& :hover {
    & svg {
      transform: translateX(-3px);
    }
  }
`
const ReviewCard = styled.div`
  position: relative;
  width: calc(100% - 30px) !important;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  border:1px solid transparent;
  transition: all 0.2s ease;
  margin: 20px 15px;
  padding: 30px;
  ${BreakpointUp.xl`
    margin: 30px 15px;
    padding: 50px;
  `}
  & svg {
    position: absolute;
    width: 48px;
    height: 40px;
    top: -20px;
    display: ${(props) => props.display};
  }
  & p {
    font-style: italic;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }
  &:hover {
    cursor: pointer;
    background: #fffaef;
    border-color:#fab702;
    ${BreakpointUp.xl`
      margin:15px;
      padding: 65px 50px;
    `}
  }
`
const ButtonToolbar = styled.div`
  text-align:center;
`

function SampleNextArrow(props) {
  const { className, style } = props
  return <div className={className} style={{ ...style, display: "none" }} />
}

function SamplePrevArrow(props) {
  const { className, style } = props
  return <div className={className} style={{ ...style, display: "none" }} />
}

class ReviewsCarousel extends React.Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }
  next() {
    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }
  render() {
    const { title, data } = this.props
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1
          },
        },
      ],
    }
    return (
      <Section xpt="80px" xpb="80px" mpt="60px" mpb="60px" pt="30px" pb="30px" bgColor="#edf8ff">
        <Container>
          <SectionTitle textAlign="center">{title}</SectionTitle>
          <SliderWrap>
            <SlickArrow>
              <LeftArrow onClick={this.previous} className="slick-arrow">
                <BackArrowIcon fill="#FAB702" />
              </LeftArrow>
              <RightArrow onClick={this.next} className="slick-arrow">
                <ArrowIcon fill="#FAB702" />
              </RightArrow>
            </SlickArrow>
            <Slider {...settings} ref={(c) => (this.slider = c)}>
              {data.edges.map((item, i) => {
                return (
                  <ItemHover>
                    {(hoverItem) => {
                      return (
                        <ReviewCard display={hoverItem ? "block" : "none"}>
                          <QuotationMark fill="#FAB702" />
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.node.review.childMarkdownRemark.html,
                            }}
                          />
                          <WriterWrap>
                            <Avatar>
                              <GatsbyImage
                                image={getImage(item.node.customerImage)}
                                alt="AES"
                              />
                            </Avatar>
                            <Writer>
                              <span className="author">{item.node.customerName}</span>
                              <span className="role text-muted">{item.node.customerRole}</span>
                            </Writer>
                          </WriterWrap>
                        </ReviewCard>
                      )
                    }}
                  </ItemHover>
                )
              })}
            </Slider>
          </SliderWrap>
          <ButtonToolbar>
            <Link to="/review"><PrimaryButton text="See All Reviews..." /></Link>
          </ButtonToolbar>
        </Container>
      </Section>
    )
  }
}

export default ReviewsCarousel
