import React from 'react'

const QuotationMark = (props)=> (
	<svg xmlns="http://www.w3.org/2000/svg" width="260.649" height="220" viewBox="0 0 260.649 220">
	  <path id="Path_453" data-name="Path 453" d="M125.213-131.852c0-7.314-4.063-8.939-9.752-7.314C52.885-118.036,9-45.707,9,11.993,9,60.754,39.882,80.258,68.326,80.258c33.32,0,59.326-26.818,59.326-60.138,0-32.507-24.38-56.075-60.138-63.389,8.94-24.38,28.444-40.634,52.012-51.2,4.063-1.625,5.689-4.063,5.689-9.752Zm142,0c0-7.314-4.063-8.939-9.752-7.314C194.882-118.036,151-45.707,151,11.993c0,48.761,30.882,68.265,59.326,68.265,33.32,0,59.326-26.818,59.326-60.138,0-32.507-24.38-56.075-60.138-63.389,8.939-24.38,28.444-40.634,52.012-51.2,4.063-1.625,5.689-4.063,5.689-9.752Z" transform="translate(-9 139.742)" fill={props.fill}/>
	</svg>
)

QuotationMark.defaultProps = {
	fill: "#f8f8f8"
}

export default QuotationMark