import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import {
  BreadCrumb,
  Background,
  MarkdownContent,
  Section,
  Container,
  SectionPageTitle,
  Flex,
  FlexTwoCol,
  SectionTitle,
} from "../components/SectionTags"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/Header"
import IndustryHeroBg from "../components/Image/IndustryHeroBg"

import VideoBlock from "../components/VideoBlock"
import MVVideoModal from "../components/MVVideoModal"
import ArrowIcon from "../components/Icons/ArrowIcon"
import CloseIcon from "../components/Icons/CloseIcon"
import ItemHover from "../components/ItemHover"
import { dropdown } from "../components/Animation"
import ServiceDropDown from "../components/ServiceDropDown"

import FactsAboutAes from "../components/FactsAboutAes"
import ReviewsCarousel from "../components/ReviewsCarousel"
import EmergencyEstimateCTA from "../components/CallToAction/EmergencyEstimateCTA"
import BreakpointUp from "../components/Media/BreakpointUp"

const Sections = styled(Section)`
  &:before{
    height:50%;
    ${BreakpointUp.md`
      height:60%;
    `}
  }
`
const FlexLgTwoCol = styled(FlexTwoCol)`
  ${BreakpointUp.md`
    flex: 0 0 100%;
    max-width: 100%;
  `}
  ${BreakpointUp.lg`
    flex: 0 0 50%;
    max-width: 50%;
  `}
`
const ProductMedia = styled.div`
  text-align:left;
  margin-bottom: 30px;
  ${BreakpointUp.lg`
    text-align:right;
    margin-bottom: 0;
  `}
`
const OffersSection = styled.div`
  margin-top:15px;
  ${BreakpointUp.xl`
    margin-top:30px;
    padding-right:calc((100% - 1350px) / 2);
  `}
`
const OffersContentWrap = styled.div`
  position: relative;
  background: white;
  box-shadow: 0 15px 65px rgb(0 0 0 / 15%);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 30px 15px;
  ${BreakpointUp.sm`
    padding: 30px;
  `}
  ${BreakpointUp.lg`
    padding: 60px;
    padding-left: calc(100% - 990px);
  `}
  ${BreakpointUp.xl`
    padding: 80px;
    padding-left: calc(100% - 1350px);
  `}
`

const VerticalTitle = styled.div`
  position: absolute;
  top: 50%;
  font-size: 50px;
  color: rgba(51,51,51,0.1);
  height: 50px;
  transform-origin: 0 0;
  transform: rotate(90deg) translateX(0%);
  left: calc(100% - 25px);
  white-space: nowrap;
  /* display:none; */
`
const VideoWrap = styled.div`
  position: relative;
`
const UspItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dedede;
  padding: 20px 0;  
  &:hover {
    cursor: pointer;
    color: #fab702 !important;    
  }
`
const UspFeature = styled.div`
   font-family: 'UniNeueBold', sans-serif;
  font-weight: 800;
  font-size: 18px;
  line-height: 28px;
  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 32px;
  }
  @media (min-width: 992px) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (min-width: 1200px) {
    font-size: 28px;
    line-height: 38px;
  }
  width: calc(100% - 140px);
`
const UspIcon = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 70px;
  text-align: center;
  border: 3px solid #dfeefb;
  margin-right: 20px;
  & img {
    margin-top: 50%;
    transform: translateY(-50%);
  }
`
const UspPopup = styled.div`
  position: absolute;
  z-index: 10;
  display: ${(props) => props.display};
  background: #fab702;
  color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 20px 65px rgba(0, 0, 0, 0.25);
  top: 0;
  left: 0;
  width:100%;
  ${BreakpointUp.lg`
    top: -30px;
    left: -40px;
    width: calc(100% + 70px);
  `}
`
const UspPopupClose = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  fill: white;
  width: 30px;
  height:30px;
  cursor: pointer;
  display:flex;
  align-items:center;
  justify-content:center;
  
  > svg{
    height: 16px;
  }
`
const UspPopupBody = styled.div`
  display:flex;
  flex-wrap: wrap;
  align-items: center;
  margin:0 -10px;
`
const UspPopupThumb = styled.div`
  position:relative;
  width:100%;
  padding:0 10px;
  ${BreakpointUp.md`
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  `}  
  `
const UspPopupContent = styled.div`
  position:relative;
  width:100%;
  padding:0 10px;
  ${BreakpointUp.md`
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  `}  
  & strong {
    display:block;
  }
  & p, & strong {
    color: white;
    margin-bottom: 0
  }
`

const DropWrap = styled.div`
  position: relative;
`

const DropButton = styled.div`
  position: relative;
  width: 100%;
  color: white;
  font-size: 20px;
  line-height: 72px;
  font-family: 'UniNeueBold', sans-serif;
  font-weight: 700;
  border: 1px solid rgba(165, 205, 240, 0.6);
  border-radius: 5px;
  padding: 0 30px;
  cursor: pointer;
  background: ${(props) => props.bg};
  color: white;
  animation: ${dropdown} ease 0.5s;
  z-index: 20;
  & svg {
    float: right;
    margin-top: 32px;
    transform: rotate(90deg);
  }
`
class WhyChooseUs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVideoModalOpen: false,
      currentVideo: { src: "", type: "" },
      currentUsp: "",
      isVisible: false,
    }
    this.handleVideo = this.handleVideo.bind(this)
    this.openVideoModal = this.openVideoModal.bind(this)
    this.closeVideoModal = this.closeVideoModal.bind(this)
    this.openUspPopup = this.openUspPopup.bind(this)
    this.closeUspPopup = this.closeUspPopup.bind(this)
  }
  playVideo() {
    this.refs.vidRef.play()
  }
  openVideoModal(url) {
    this.setState({
      currentVideo: {
        type: "video/youtube",
        src: url,
      },
      isVideoModalOpen: true,
    })
  }
  closeVideoModal() {
    this.setState({ isVideoModalOpen: false })
  }
  handleVideo(url) {
    this.openVideoModal(url)
  }
  openUspPopup(data) {
    this.setState({
      currentUsp: data,
      isVisible: true,
    })
  }
  closeUspPopup() {
    this.setState({
      currentUsp: "",
      isVisible: false,
    })
  }
  render() {
    const pageData = this.props.data.contentfulWhyChooseUsPage
    const testimonials = this.props.data.allContentfulTestimonial
    const { currentUsp, isVisible } = this.state
    return (
      <Layout>
        <Seo title={pageData.title} />
        <div className="sticky-wrapper sticky">
          <Header />
        </div>
        <MVVideoModal
          isOpen={this.state.isVideoModalOpen}
          autoPlay
          src={this.state.currentVideo.src}
          type={this.state.currentVideo.type}
          controls
          onClose={this.closeVideoModal}
        />
        <Sections xpt="120px" mpt="120px" pt="90px" pb="0"
        bgBeforeWidth="100%"
        topAfter="0"
        bgBefore="linear-gradient(to bottom,#ffffff 0%, #f5f5f5 100%)">
          <Background height="auto" width="50%" left="0px"><IndustryHeroBg /></Background>
          <Container>
            <BreadCrumb>
              <Link to="/"><span>Home</span></Link> {pageData.title}
            </BreadCrumb>
            <Flex alignItem="center" justifyContent="space-between">
              <FlexLgTwoCol>
                <SectionPageTitle>{pageData.title}</SectionPageTitle>
                <MarkdownContent
                  dangerouslySetInnerHTML={{
                    __html: pageData.description.childMarkdownRemark.html,
                  }}
                />
              </FlexLgTwoCol>
              <FlexLgTwoCol>
                <ProductMedia>
                  <VideoWrap>
                    <VideoBlock
                      action={() => this.handleVideo(pageData.videoUrl)}
                      video={pageData.videoUrl}
                    />
                  </VideoWrap>
                </ProductMedia>
              </FlexLgTwoCol>
            </Flex>
          </Container>
          <OffersSection>
            <Background height="auto" width="50%" top="auto" right="0px" bottom="0"><IndustryHeroBg /></Background>
            <VerticalTitle>{pageData.uspSection.secondTitle}</VerticalTitle>
            <Container maxWidth="100%" pl="0" pr="0">
              <OffersContentWrap>
              <Flex alignItem="center" justifyContent="space-between">
                  <FlexLgTwoCol>
                    <MarkdownContent
                      dangerouslySetInnerHTML={{
                        __html:
                          pageData.uspSection.description.childMarkdownRemark.html,
                      }}
                    />
                  </FlexLgTwoCol>
                  <FlexLgTwoCol>
                  {pageData.uspSection.features.map((item, i) => {
                      return (
                        <div style={{ position: "relative" }}>
                          <UspItem
                            key={i}
                            onClick={() => this.openUspPopup(item.name)}
                          >
                            <UspIcon>
                              <img src={item.icon} alt={item.name} />
                            </UspIcon>
                            <UspFeature>{item.name}</UspFeature>
                            <ArrowIcon fill="#FAB702" />
                          </UspItem>
                          <UspPopup
                            display={
                              currentUsp === item.name && isVisible === true
                                ? "flex"
                                : "none"
                            }
                          >
                            <UspPopupClose onClick={() => this.closeUspPopup()}>
                              <CloseIcon />
                            </UspPopupClose>
                            <UspPopupBody>
                              <UspPopupThumb>
                                <img src={item.whiteIcon} alt={item.name} />
                              </UspPopupThumb>
                              <UspPopupContent>
                                <strong className="h4">{item.name}</strong>
                                {/**<p>{item.description}</p>**/}
                              </UspPopupContent>
                            </UspPopupBody>
                          </UspPopup>
                        </div>
                      )
                    })} 
                  </FlexLgTwoCol>
                </Flex>
              </OffersContentWrap>
            </Container>
          </OffersSection>
        </Sections>
        <Section xpt="100px" xpb="100px" mpt="80px" mpb="80px" pt="40px" pb="40px">
          <Container>
            <Flex alignItem="center" justifyContent="space-between">
              <FlexTwoCol flexWidth="42%" flexMWidth="42%">
                <SectionTitle>No Matter The Equipment. We Got You Covered!</SectionTitle>
              </FlexTwoCol>
              <FlexTwoCol flexWidth="50%" flexMWidth="50%">
                <DropWrap>
                  <ItemHover>
                    {(hoverItem) => (
                      <div>
                        <DropButton bg={hoverItem ? "#FAB702" : "#0059B2"}>
                          Help Me With <ArrowIcon fill="white" />
                        </DropButton>
                        <ServiceDropDown
                          width="100%"
                          display={hoverItem ? "block" : "none"}
                        />
                      </div>
                    )}
                  </ItemHover>
                </DropWrap>
              </FlexTwoCol>
            </Flex>
          </Container>
        </Section>
        <FactsAboutAes data={pageData.factsSection} />
        <ReviewsCarousel
          title={pageData.testimonialSectionTitle}
          data={testimonials}
        />
        <EmergencyEstimateCTA
          leftData={pageData.emergencyCta}
          rightData={pageData.estimateCta}
        />
      </Layout>
    )
  }
}

export default WhyChooseUs

export const pageQuery = graphql`
  query WhyChooseUsPageQuery {
    contentfulWhyChooseUsPage {
      title
      description {
        childMarkdownRemark {
          html
        }
      }
      videoUrl
      uspSection {
        title
        secondTitle
        description {
          childMarkdownRemark {
            html
          }
        }
        features {
          name
          icon
          whiteIcon
          description
        }
      }
      factsSection {
        title
        features {
          amount
          fact
        }
      }
      testimonialSectionTitle
      emergencyCta {
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
      estimateCta {
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
    }
    allContentfulTestimonial {
      edges {
        node {
          customerName
          customerRole
          review {
            childMarkdownRemark {
              html
            }
          }
          customerImage {
            gatsbyImageData(quality: 100)
          }
        }
      }
    }
  }
`
