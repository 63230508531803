import React from 'react'

const BackArrowIcon = (props) =>(
	<svg xmlns="http://www.w3.org/2000/svg" width="18.002" height="18" viewBox="0 0 18.002 18">
	  <g id="arrow" transform="translate(18.002 18) rotate(180)">
	    <rect id="Rectangle_2588" data-name="Rectangle 2588" width="18" height="18" fill="#fff" opacity="0"/>
	    <path id="Union_21" data-name="Union 21" d="M12738.242,24381l-3.838-6,10.1,6-10.1,6Zm-4.462,1v-2h2.074v2Zm-3.636,0v-2h2.075v2Zm-3.636,0v-2h2.075v2Z" transform="translate(-12726.507 -24372.004)" fill="#fab702"/>
	  </g>
	</svg>
)

BackArrowIcon.defaultProps = {
	fill: "#fab702"
}

export default BackArrowIcon