import React from "react"
import styled from "styled-components"
import { Section, BackgroundImg, Container, SectionTitle } from "../SectionTags"
import Bg from "../../images/choose-left.png"
import BreakpointUp from "../Media/BreakpointUp"
import BreakpointDown from "../Media/BreakpointDown"

const Grid = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;  
  margin:0 -5px;
`
const GridLeft = styled.div`
  position: relative;
  width: 100%;
  padding:0 5px;
  ${BreakpointUp.xl`
    flex: 0 0 30%;
    max-width: 30%;
  `}
`
const GridRight = styled.div`
  position: relative;
  width: 100%;
  padding:0 5px;
  ${BreakpointUp.xl` 
    flex: 0 0 70%;
    max-width: 70%;
  `}
`
const Fact = styled.div`
  position: relative;
  border:6px solid rgba(255,255,255,0.5);
  border-radius: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 15px auto;
  width: 140px;
  height: 140px;
  ${BreakpointUp.xl` 
    width: 160px;
    height: 160px;
  `}
  ${BreakpointUp.xxl` 
    width: 170px;
    height: 170px;
  `}
  & svg {
    position: absolute;
    top: 0px;
    left: -5px;
  }
  
`
const FactText = styled.div`
  max-width: 100px;
  width:100%;
  & strong, & span {
    display:block;

  }
  & strong {
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 800;
    margin:0 0 5px; 
    color: #FAB702;
    font-size: 20px;
    line-height: 30px;
    @media (min-width: 768px) {
      font-size: 24px;
      line-height: 32px;
    }
    @media (min-width: 992px) {
      font-size: 32px;
      line-height: 44px;
    }
  }
  & span {
    font-size: 14px;
    line-height: 18px;
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 700;
    color: white;
    white-space: normal;
  }
`
const FactList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding:0 0 15px;
  list-style:none;
  margin:0 -15px;
  ${BreakpointUp.md` 
    padding:0;
    margin:0 -5px;
  `}
  ${BreakpointDown.md` 
    overflow: hidden;
    white-space: nowrap;
    overflow-x: auto;
    flex-wrap: nowrap;
  `}

`
const FactItem = styled.li`
  position: relative;
  width: 100%;
  margin:0;
  padding:0 15px;
  flex: 0 0 50%; 
  max-width: 50%; 
  ${BreakpointUp.sm` 
    flex: 0 0 25%; 
    max-width: 25%;
  `}
  ${BreakpointUp.md` 
    padding:0 5px;
    flex: 0 0 20%; 
    max-width: 20%;
  `}
  + li{
    .fact{
      &:before{
        position: absolute;
        left: 50%;
        top: -4px;
        content: "";
        background: #fab702;
        width: 30px;
        height: 5px;
        transform: rotate(10deg);
        border-radius: 2px;
      }

    } 
  }
`

class FactsAboutAes extends React.Component {
  render() {
    const { data } = this.props
    return (
      <Section xpt="80px" xpb="80px" mpt="60px" mpb="60px" pt="30px" pb="30px" bgColor="rgba(0, 89, 178, 0.92)">
        <BackgroundImg bg={Bg} />
        <Container>
          <Grid>
            <GridLeft>
              <SectionTitle mb="0px" color="#fff">{data.title}</SectionTitle>
            </GridLeft>
            <GridRight>
              <FactList>
                {data.features.map((item, i) => {
                    return (
                      <FactItem key={i}>
                        <Fact className="fact">
                          {/* <FactCircle /> */}
                          <FactText>
                            <strong>{item.amount}</strong>
                            <span>{item.fact}</span>
                          </FactText>
                        </Fact>
                      </FactItem>
                    )
                  
                })}
              </FactList>
            </GridRight>
          </Grid>
        </Container>
      </Section>
    )
  }
}

export default FactsAboutAes
