import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { dropdown } from "../Animation"

const DropWrap = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  left: 0;
  padding: 0 30px;
  background: #0059b2;
  width: ${(props) => props.width};
  display: ${(props) => props.display};
  border: 1px solid rgba(165, 205, 240, 0.6);
  border-radius: 8px;
  box-shadow: 0 15px 45px rgba(0, 128, 255, 0.4);
  animation: ${dropdown} ease 0.5s;
  & a {
    color: white;
    font-size: 20px;
    line-height: 72px;
    font-weight: 700;
    font-family: 'UniNeueBold', sans-serif;
  }
  &:hover {
    z-index: 9;
  }
  @media (max-width: 480px) {
    width: mWidth;
  }
`
DropWrap.defaultProps = {
  top: "68px",
  mWidth: "300px",
}
const Divider = styled.div`
  border-top: 1px solid #317cc6;
  width: 100%;
`
const Triangle = styled.div`
  position: relative;
  box-sizing: border-box;
  background: #0059b2;
  box-shadow: 0px 25px 65px rgba(1, 9, 32, 0.2);

  & :after {
    content: "";
    position: absolute;
    top: ${(props) => props.top};
    width: 0;
    height: 0;
    left: 50%;
    box-sizing: border-box;
    border: 0.5em solid;
    border-color: transparent transparent #0059b2 #0059b2;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -3px 3px 4px -2px rgba(0, 0, 0, 0.1);
  }
`

class ServiceDropDown extends React.Component {
  render() {
    const { width, display, top, triangleTop } = this.props
    return (
      <DropWrap width={width} display={display} top={top}>
        <Triangle top={triangleTop} />
        <Link to="/electronic-repair-services">Electronic Repair</Link>
        <Divider />
        <Link to="/mechanical-repair-services">Mechanical Repair</Link>
        <Divider />
        <Link to="/hydraulic-repair-services">Hydraulic Repair</Link>
        <Divider />
        <Link to="/servo-repair-services">Servo Repair</Link>
        <Divider />
        <Link to="/kiosk-printer-repair-services">Kiosk & Printer Repair</Link>
      </DropWrap>
    )
  }
}

export default ServiceDropDown
